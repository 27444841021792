.resultMapError {
  width: 55%;
  padding: 40px 24px;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  color: #2d3035;
  z-index: 1;
  background: white;
  border-radius: 15px;
  text-align: center;
  &Desc {
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    margin-top: 12px;
  }
}

@media screen and (max-width: 1024px) {
  .resultMapError {
    width: 65%;
  }
}

@media screen and (max-width: 768px) {
  .resultMapError {
    max-width: 500px;
    width: 90%;
    padding: 30px 20px;
    font-size: 20px;
    line-height: normal;
  }
}
