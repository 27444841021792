// Home

.home {
  position: relative;
  &Banner {
    position: absolute;
    top: 0;
    right: -78px;
    width: 52.7vw;
    max-width: 714px;
  }
  &Container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    min-height: 470px;
  }
  &ContentLeft {
    width: 55%;
    max-width: 625px;
    padding: 111px 0 131px 0;
    h1 {
      font-size: 36px;
      font-weight: 500;
      line-height: 56px;
      color: #2d3035;
    }
    h5 {
      margin-top: 20px;
      font-size: 22px;
      font-weight: 700;
      color: #196faf;
    }
    p {
      margin-top: 26px;
      font-size: 18px;
      font-weight: 400;
      line-height: 177.778%;
      color: #2d3035;
    }
  }
}

@media screen and (max-width: 768px) {
  .home {
    position: relative;
    margin-top: 72px;
    margin-bottom: 20px;
    &Banner {
      display: none;
      // width: 70%;
      // position: static;
      // margin-left: auto;
      // margin-right: -5%;
      // margin-bottom: 30px;
      // display: flex;
    }
    &Container {
      text-align: left;
      min-height: unset;
    }
    &ContentLeft {
      width: 100%;
      max-width: unset;
      padding: unset;
      h1 {
        font-size: 20px;
        line-height: 26px;
      }
      h5 {
        margin-top: 10px;
        font-size: 13px;
      }
      p {
        display: none;
        // margin-top: 15px;
        // font-size: 14px;
        // line-height: 23px;
      }
    }
  }
}
