// Goals

.goals {
  padding: 74px 0 100px 0;
  &Title {
    text-align: center;
    font-size: 36px;
    font-weight: 500;
    line-height: 155.556%;
    color: #196faf;
  }
  &Description {
    margin: 16px 0 58px 0;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 177.778%;
    color: #2d3035;
  }
  &Content {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    &Item {
      flex: 0 1 26%;
      text-align: center;
      h4 {
        margin: 30px 0 20px 0;
        font-size: 24px;
        font-weight: 600;
        line-height: 141.667%;
        color: #2d3035;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 156.25%;
        color: #717780;
      }
    }
  }
}

// Subscribe

.subscribe {
  font-family: 'Roboto';
  padding: 60px 0 80px 0;
  background: #f3f6f9;
  &Container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &Title {
    font-weight: 500;
    font-size: 36px;
    line-height: 156%;
    color: #2d3035;
    margin-bottom: 14px;
  }
  &SubTitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 156%;
    color: #717780;
    margin-bottom: 40px;
  }
  &Input {
    font-family: 'Roboto';
    box-sizing: border-box;
    width: 400px;
    background: #ffffff;
    border: 1px solid #b3bcc9;
    border-radius: 4px;
    padding: 12px 14px;
    margin-right: 20px;
  }
  &Button {
    font-family: 'Roboto';
    padding: 12px 13px;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    background: #74b566;
    border-radius: 4px;
    transition: all 0.3s;
    cursor: pointer;
    border-width: 0;
    &:hover {
      opacity: 0.7;
    }
  }
  &Button::-moz-placeholder {
    font-weight: 400;
    color: #b3bcc9;
  }
  &Button:-ms-input-placeholder {
    font-weight: 400;
    color: #b3bcc9;
  }
  &Button::placeholder {
    font-weight: 400;
    color: #b3bcc9;
  }
}

@media screen and (max-width: 768px) {
  .goals {
    padding: 40px 0 50px 0;
    &Title {
      font-size: 20px;
      line-height: 28px;
    }
    &Description {
      margin: 14px 0 36px 0;
      font-size: 14px;
      line-height: 23px;
    }
    &Content {
      flex-direction: column;
      row-gap: 30px;
      &Item {
        flex: 0 1 26%;
        text-align: center;
        img {
          width: 90px;
        }
        h4 {
          margin: 20px 0 10px 0;
          font-size: 16px;
        }
        p {
          font-size: 14px;
          line-height: 23px;
        }
      }
    }
  }
  .subscribe {
    padding: 40px 0 50px 0;
    &Container {
      text-align: center;
      form {
        width: 100%;
      }
    }
    &Title {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 20px;
    }
    &SubTitle {
      font-size: 14px;
      line-height: 28px;
      margin-bottom: 30px;
    }
    &Group {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
    }
    &Input {
      width: 100%;
      margin-right: unset;
    }
    &Button {
      margin: 0 auto;
      width: 161px;
      font-size: 14px;
    }
  }
}
