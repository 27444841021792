.error {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 70px;
  padding-top: 50px;
  margin-top: 50px;
  border-top: 1px solid #d2dbe8;
  &Text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  &Title {
    color: #303134;
    font-size: 25px;
    font-weight: 700;
    line-height: 35px;
    margin-bottom: 10px;
  }
  &SubTitle {
    color: #303134;
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    margin-bottom: 24px;
  }
  &Description {
    color: #2d3035;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }
  &Recalculate {
    align-self: flex-start;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
    color: #5796d2;
    border: 2px solid #5796d2;
    border-radius: 4px;
    padding: 12px 32px;
    margin-top: 12px;
    background-color: #fff;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

@media screen and (max-width: 768px) {
  .error {
    column-gap: 20px;
    padding-top: 30px;
    margin-top: 30px;
    &Icon {
      svg {
        width: 100px;
        height: 100px;
      }
    }
    &Title {
      font-size: 18px;
      line-height: normal;
      margin-bottom: 10px;
    }
    &SubTitle {
      font-size: 14px;
      line-height: normal;
      margin-bottom: 10px;
    }
    &Description {
      font-size: 12px;
      line-height: normal;
    }
  }
}
