.counter {
  margin: 10px 0;
  display: flex;
  align-items: center;
  column-gap: 24px;
  &Btn {
    padding: 5px 7px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    border: 1px solid #b3bcc9;
    background-color: unset;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
      background-color: #f3f6f9;
    }
  }
  &Number {
    color: #2d3035;
    font-size: 22px;
    font-weight: 500;
    line-height: normal;
  }
}
@media screen and (max-width: 425px) {
  .counter {
    justify-content: center;
  }
}
