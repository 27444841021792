.tab {
  &Container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f8fafd;
    z-index: 10000;
    padding: 6px 0;
  }
  &Items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  &Label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
    color: #5d5d5d;
    padding: 0px 10px;
    border: none;
    background-color: unset;
    &:disabled {
      cursor: not-allowed;
      color: #b3bcc9;
      svg {
        path {
          fill: #b3bcc9;
        }
      }
    }
  }
  &Active {
    color: #2d3035;

    &:focus {
      background-color: 'white';
    }
    svg {
      background-color: #d1e9ff;
      border-radius: 16px;
      path {
        fill: #2d3035;
      }
    }
  }
}
