.slick-arrow {
  top: unset;
  transform: unset;
  bottom: -35px;
  width: 40px;
  height: 40px;
  border: 2px solid #5796d2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  &::before {
    content: none;
  }
}
.slick-next {
  right: 0;
  svg {
    transform: rotate(180deg);
    path {
      fill: #ffffff;
    }
  }
}
.slick-prev {
  left: 0;
}

.slick-dots li button:before {
  font-size: 13px;
  color: #dde0e4;
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #ef6f22;
}

.slick-list {
  padding-bottom: 34px;
  margin-bottom: 30px;
}
